<template>
    <li class="skills-item">
        <div class="title-wrapper">
            <h5 class="h5">{{ skill }}</h5>
            <data :value="level">{{ level }}%</data>
        </div>
        <div class="skill-progress-bg">
            <div class="skill-progress-fill" :style="{ width: level + '%' }"></div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'SkillItem',
    props: {
        skill: {
            type: String,
            required: true
        },
        level: {
            type: Number,
            required: true
        }
    }
};
</script>