<template>
    <article class="resume" data-page="resume">
        <header>
            <h2 class="h2 article-title">Resume</h2>
        </header>

        <section class="timeline">
            <div class="title-wrapper">
                <div class="icon-box">
                    <font-awesome-icon :icon="['fas', 'book-open']" />
                </div>
                <h3 class="h3">Education</h3>
            </div>
            <ol class="timeline-list">
                <EducationItem v-for="edu in education" :key="edu.id" :title="edu.title" :date="edu.date"
                    :description="edu.description" />
            </ol>
        </section>

        <section class="timeline">
            <div class="title-wrapper">
                <div class="icon-box">
                    <font-awesome-icon :icon="['fas', 'book-open']" />
                </div>
                <h3 class="h3">Experience</h3>
            </div>
            <ol class="timeline-list">
                <ExperienceItem v-for="exp in experience" :key="exp.id" :title="exp.title" :date="exp.date"
                    :description="exp.description" />
            </ol>
        </section>

        <section class="skill">
            <h3 class="h3 skills-title">My skills</h3>
            <ul class="skills-list content-card">
                <SkillItem v-for="skill in skills" :key="skill.id" :skill="skill.name" :level="skill.level" />
            </ul>
        </section>
        <br>
        <button class="form-btn" @click="downloadResume" disabled>Download Resume</button>
    </article>
</template>

<script>

import ExperienceItem from '../ExperienceItem.vue';
import EducationItem from '../EducationItem.vue';
import SkillItem from '../SkillItem.vue';

export default {
    name: 'ResumeView',
    components: {
        ExperienceItem,
        EducationItem,
        SkillItem,
    },
    methods: {
        downloadResume() {
            const link = document.createElement('a');
            link.href = '../assets/resume/resume.txt';
            link.setAttribute('download', 'resume.txt');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    data() {
        return {
            education: [
                {
                    id: 1,
                    title: 'Bachelor of Science in Computer Science and Cybersecurity',
                    date: 'Graduated: May 2023',
                    description: 'Hofstra University - GPA: 3.50/4.0'
                }
            ],
            experience: [
                {
                    id: 1,
                    title: 'Software Engineer',
                    date: 'September 2023 — Present',
                    description: 'ECM PCB Stator Technology - Designing and developing software solutions for PCB stator technology.'
                },
                {
                    id: 2,
                    title: 'Data Analyst Intern',
                    date: 'May 2022 — August 2022',
                    description: 'GlaxoSmithKline (GSK), Remote - Developed an admin tool to streamline data processing, conducted product demos, and automated reporting systems.'
                },
                {
                    id: 3,
                    title: 'Math Mentor',
                    date: 'July 2020 — August 2021',
                    description: 'New Opportunities at Hofstra Scholar Program - Mentored students, led workshops on goal setting, and organized team-building activities.'
                }
            ],
            skills: [
                { id: 1, name: 'C++, C, C#, Python', level: 90 },
                { id: 2, name: 'JavaScript, HTML, CSS, ASP.NET', level: 80 },
                { id: 3, name: 'GitHub, Jira', level: 85 },
                { id: 4, name: 'Linux, Windows, MacOS', level: 80 },
                { id: 5, name: 'Software design and testing', level: 90 },
                { id: 6, name: 'Bilingual in English and Spanish', level: 100 }
            ]
        };

    }
};
</script>
