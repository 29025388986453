<template>
    <nav class="navbar">
      <ul class="navbar-list">
        <li v-for="item in navItems" :key="item.name">
          <router-link :to="item.path" class="navbar-link">{{ item.name }}</router-link>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    data() {
      return {
        navItems: [
          { name: 'Home', path: '/home' },
          { name: 'About', path: '/about' },
          { name: 'Resume', path: '/resume' },
          { name: 'Portfolio', path: '/portfolio' },
          { name: 'Blog', path: '/blog' },
          { name: 'Contact', path: '/contact' }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  </style>
  
<!-- Install npm install vue-router -->