<template>
    <aside v-if="isAtHome || isAtContact" class="local_sidebar">
        <div class="sidebar-info">
            <Avatar :src="avatarSrc" :alt="avatarAlt" />
            <UserInfo :name="userName" :title="userTitle" />
        </div>
        <div v-if="(!isMobile) || (isMobile && isAtContact)">
            <div class="separator"></div>
            <ul class="contacts-list">
                <ContactItem icon="envelope" title="Email">
                    <a href="mailto:warlyn@wramirez.dev" class="contact-link">warlyn@wramirez.dev</a>
                </ContactItem>
                <ContactItem icon="fa-phone" title="Phone">
                    <a href="tel:+xxxxxxxxxxx" class="contact-link">+x (xxx) xxx-xxxx</a>
                </ContactItem>
                <ContactItem icon="cake-candles" title="Birthday">
                    <time datetime="1982-06-23">September 13, 2000</time>
                </ContactItem>
                <ContactItem icon="map-marker-alt" title="Location">
                    <address>Boston, Massachusetts, USA</address>
                </ContactItem>
            </ul>
            <div class="separator"></div>
            <ul class="social-list">
                <SocialItem link="#" icon="facebook" />
                <SocialItem link="#" icon="github" />
                <SocialItem link="#" icon="instagram" />
            </ul>
        </div>
    </aside>
</template>

<script>
import Avatar from './AvatarBox.vue';
import UserInfo from './UserInfo.vue';
import ContactItem from './ContactItem.vue';
import SocialItem from './SocialItem.vue';

export default {
    components: {
        Avatar,
        UserInfo,
        ContactItem,
        SocialItem
    },
    data() {
        return {
            avatarSrc: require('@/assets/images/suit.jpg'),
            avatarAlt: 'Warlyn Ramirez',
            userName: 'Warlyn Ramirez',
            userTitle: 'Software Engineer',
            isMobile: false
        };
    },
    created() {
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkIfMobile);
    },
    methods: {
        checkIfMobile() {
            this.isMobile = window.innerWidth <= 768; // Adjust this width as needed
        }
    },
    computed: {
        isAtHome() {
            return this.$route.name === 'home';
        },
        isAtContact() {
            return this.$route.name === 'contact';
        }
    }
};
</script>

<style scoped>
.local_sidebar {
    padding-bottom: 20px; /* Adjust the padding as needed */
}
</style>