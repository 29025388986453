<template>
    <article class="blog">
        <header>
            <h2 class="h2 article-title">Blog</h2>
        </header>
        <h2 class="h2 article-title">[COMING SOON]]</h2>
        <section class="blog-posts">
            <ul class="blog-posts-list">
                <BlogItem 
                    v-for="post in posts" 
                    :key="post.id" 
                    :title="post.title" 
                    :excerpt="post.excerpt" 
                    :imageSrc="post.imageSrc" 
                    :imageAlt="post.imageAlt" 
                    :dateTime="post.dateTime" 
                    :formattedDate="post.formattedDate" 
                    :category="post.category" 
                    :link="post.link"
                />
            </ul>
        </section>
    </article>
</template>

<script>
import BlogItem from '../BlogItem.vue';

export default {
    name: 'BlogView',
    components: {
        BlogItem
    },
    data() {
        return {
            posts: [
                {
                    id: 1,
                    title: 'Example blog 2024',
                    excerpt: 'Small description of what it is',
                    imageSrc: require('@/assets/images/blog-1.jpg'),
                    imageAlt: 'Img decription',
                    dateTime: '2024-10-28',
                    formattedDate: 'Oct 28, 2024',
                    category: 'Software',
                    link: '#'
                },
                // Add other posts here...
            ]
        };
    }
};
</script>