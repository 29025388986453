<template>
  <!--
    - #PORTFOLIO
  -->

  <article class="portfolio">

    <header>
      <h2 class="h2 article-title">Portfolio</h2>
    </header>
    <h3 class="h2 article-title">[COMING SOON]</h3>
    <section class="projects">
      <!--
        - #GitHub contribution chart
      -->
      <div class="github-contributions">
        <h3 class="h3 article-title">Contributions</h3>
        <iframe
          src="https://ghchart.rshah.org/0000FF/warlynrn"
          width="100%"
          frameborder="0"
        ></iframe>
      </div>
      <ul class="filter-list">
        <li class="filter-item">
          <button class="active" data-filter-btn>All</button>
        </li>
        <li class="filter-item">
          <button data-filter-btn>Web design</button>
        </li>
        <li class="filter-item">
          <button data-filter-btn>Applications</button>
        </li>
        <li class="filter-item">
          <button data-filter-btn>Web development</button>
        </li>
      </ul>

      <div class="filter-select-box">
        <button class="filter-select" data-select>
          <div class="select-value" data-select-value>Select category</div>
          <div class="select-icon">
            <font-awesome-icon :icon="['fas', 'chevron-down']" />
          </div>
        </button>
        <ul class="select-list">
          <li class="select-item">
            <button data-select-item>All</button>
          </li>
          <li class="select-item">
            <button data-select-item>Web design</button>
          </li>
          <li class="select-item">
            <button data-select-item>Applications</button>
          </li>
          <li class="select-item">
            <button data-select-item>Web development</button>
          </li>
        </ul>
      </div>

      <ul class="project-list">
        <ProjectItem v-for="project in projects" :key="project.title" :title="project.title"
          :category="project.category" :image="project.image" />
      </ul>

    </section>

  </article>
</template>

<script>
import ProjectItem from '../ProjectItem.vue';

export default {
  name: 'PortfolioView',
  components: {
    ProjectItem,
  },

  data() {
    return {
      projects: [
        {
          title: 'Test',
          category: 'Test',
          image: require('@/assets/images/project-1.jpg'),
        }
        // ==== Other project example ===== //
        // {
        //   title: 'Orizon',
        //   category: 'Web development',
        //   image: require('@/assets/images/project-2.png'),
        // },
        // {
        //   title: 'Fundo',
        //   category: 'Web design',
        //   image: require('@/assets/images/project-3.jpg'),
        // },
        // {
        //   title: 'Brawlhalla',
        //   category: 'Applications',
        //   image: require('@/assets/images/project-4.png'),
        // },
        // {
        //   title: 'DSM.',
        //   category: 'Web design',
        //   image: require('@/assets/images/project-5.png'),
        // },
        // {
        //   title: 'MetaSpark',
        //   category: 'Web design',
        //   image: require('@/assets/images/project-6.png'),
        // },
        // {
        //   title: 'Summary',
        //   category: 'Web development',
        //   image: require('@/assets/images/project-7.png'),
        // },
        // {
        //   title: 'Task Manager',
        //   category: 'Applications',
        //   image: require('@/assets/images/project-8.jpg'),
        // },
        // {
        //   title: 'Arrival',
        //   category: 'Web development',
        //   image: require('@/assets/images/project-9.png'),
        // },
      ],
    };
  },

  mounted() {
    const filterButtons = document.querySelectorAll("[data-filter-btn]");
    const selectButton = document.querySelector("[data-select]");
    const selectItems = document.querySelectorAll("[data-select-item]");
    const projectItems = document.querySelectorAll(".project-item"); // Correct selector
    const selectList = document.querySelector(".select-list");

    const filterProjects = (category) => {
      projectItems.forEach(item => {
        const itemCategory = item.getAttribute("data-category").toLowerCase(); // Ensure case insensitivity
        if (category === 'all' || itemCategory === category) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });
    };

    // Add click/touch events for filter buttons
    filterButtons.forEach(button => {
      button.addEventListener("click", () => {
        filterButtons.forEach(btn => btn.classList.remove("active"));
        button.classList.add("active");

        const category = button.innerText.toLowerCase();
        filterProjects(category === 'all' ? 'all' : category);
      });
    });

    // Toggle select dropdown on click/touch
    selectButton.addEventListener("click", () => {
      selectList.classList.toggle("visible");
    });

    // Handle select items click/touch
    selectItems.forEach(item => {
      item.addEventListener("click", () => {
        const category = item.innerText.toLowerCase();
        filterProjects(category === 'all' ? 'all' : category);

        document.querySelector('.select-value').innerText = item.innerText;

        // Close the select list after selection
        selectList.classList.remove("visible");
      });
    });

    // Close dropdown on touch outside (mobile specific)
    document.addEventListener("click", (event) => {
      if (!selectButton.contains(event.target) && !selectList.contains(event.target)) {
        selectList.classList.remove("visible");
      }
    });
  }
}
</script>