<template>
    <li class="social-item">
        <a :href="link" class="social-link">
            <font-awesome-icon :icon="['fab', icon]" />
        </a>
    </li>
</template>

<script>
export default {
    props: {
        link: String,
        icon: String
    },
    components: {
        
    }
};
</script>