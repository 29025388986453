import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import '@/assets/css/style.css';

// Font Awesome setup
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
//== Icons definition ===//
import { faChevronDown, faEye, faBookOpen, faEnvelope, faPhone, faBirthdayCake, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(faChevronDown, faEye, faBookOpen, faFacebook, faGithub, faInstagram, faEnvelope, faPhone, faBirthdayCake, faMapMarkerAlt);

// Create and mount the app
const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');
