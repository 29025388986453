import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/views/HomeView.vue';
import About from './components/views/AboutView.vue';
import Resume from './components/views/ResumeView.vue';
import Portfolio from './components/views/PortfolioView.vue';
import Blog from './components/views/BlogView.vue';
import Contact from './components/views/ContactView.vue';

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', name:'home', component: Home },
  { path: '/about', name:'about', component: About },
  { path: '/resume', name:'resume', component: Resume },
  { path: '/portfolio', name:'portfolio', component: Portfolio },
  { path: '/blog', name:'blog', component: Blog },
  { path: '/contact', name:'contact', component: Contact },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
