<template>
    <figure class="avatar-box">
        <img :src="src" :alt="alt" width="80" class="rounded-image">
    </figure>
</template>

<script>
export default {
    props: {
        src: String,
        alt: String
    }
};

</script>

<style>
.rounded-image {
  border-radius: 8px; 
  border: 1px solid #ccc; 
}
</style>