<template>
    <div class="info-content">
        <h1 class="name" :title="name">{{ name }}</h1>
        <p class="title">{{ title }}</p>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        title: String
    }
};
</script>
