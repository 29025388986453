<template>
    <li class="blog-post-item">
        <a :href="link">
            <figure class="blog-banner-box">
                <img :src="imageSrc" :alt="imageAlt" loading="lazy">
            </figure>
            <div class="blog-content">
                <div class="blog-meta">
                    <p class="blog-category">{{ category }}</p>
                    <span class="dot"></span>
                    <time :datetime="dateTime">{{ formattedDate }}</time>
                </div>
                <h3 class="h3 blog-item-title">{{ title }}</h3>
                <p class="blog-text">{{ excerpt }}</p>
            </div>
        </a>
    </li>
</template>

<script>
export default {
    name: 'BlogItem',
    props: {
        title: {
            type: String,
            required: true
        },
        excerpt: {
            type: String,
            required: true
        },
        imageSrc: {
            type: String,
            required: true
        },
        imageAlt: {
            type: String,
            required: true
        },
        dateTime: {
            type: String,
            required: true
        },
        formattedDate: {
            type: String,
            required: true
        },
        category: {
            type: String,
            default: 'Design'
        },
        link: {
            type: String,
            required: true
        }
    }
};
</script>