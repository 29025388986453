<template>
  <main>
    <SideBar/>
    <div class="main-content">
      <NavBar />
      <router-view />
    </div>
  </main>
</template>

<script>
import NavBar from './components/NavBar.vue';
import SideBar from './components/SideBar.vue';

export default {
  components: {
    NavBar,
    SideBar
  }
};
</script>