<template>
    <article>

        <header>
            <h2 class="h2 article-title">Home</h2>
        </header>

        <section class="about-text">
            <p>
                [COMING SOON]
            </p>
        </section>
    </article>
</template>

<script>
export default {
    name: 'HomeView',
};
</script>