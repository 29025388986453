<template>
  <li class="project-item active" data-filter-item :data-category="category">
    <a href="#">
      <figure class="project-img">
        <div class="project-item-icon-box">
          <font-awesome-icon :icon="['fas', 'eye']" />
        </div>
        <img :src="image" :alt="title" loading="lazy">
      </figure>
      <h3 class="project-title">{{ title }}</h3>
      <p class="project-category">{{ category }}</p>
    </a>
  </li>
</template>

<script>
export default {
  name: 'ProjectItem',
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
};
</script>
