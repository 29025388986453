<template>
    <li class="contact-item">
        <div class="icon-box">
            <font-awesome-icon :icon="['fas', icon]" />
        </div>
        <div class="contact-info">
            <p class="contact-title">{{ title }}</p>
            <slot></slot>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        icon: String,
        title: String
    }
};
</script>
