<template>
    <!--
    - #CONTACT
    -->
    <article class="contact" data-page="contact">

        <header>
            <h2 class="h2 article-title">Contact</h2>
        </header>
        <h2 class="h2 article-title">[COMING SOON]</h2>

        <section class="mapbox" data-mapbox>
            <figure>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188820.90327648807!2d-71.1350912246966!3d42.314231956199954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3652d0d3d311b%3A0x787cbf240162e8a0!2sBoston%2C%20MA!5e0!3m2!1sen!2sus!4v1728792691699!5m2!1sen!2sus"
                    width="400" height="300" loading="lazy"></iframe>
            </figure>
        </section>

        <section class="contact-form">

            <h3 class="h3 form-title">Contact Form</h3>

            <form action="#" class="form" data-form>

                <div class="input-wrapper">
                    <input type="text" name="fullname" class="form-input" placeholder="Full name" required
                        data-form-input>

                    <input type="email" name="email" class="form-input" placeholder="Email address" required
                        data-form-input>
                </div>

                <textarea name="message" class="form-input" placeholder="Your Message" required
                    data-form-input></textarea>
                <button class="form-btn" type="submit" disabled data-form-btn>
                    <ion-icon name="paper-plane"></ion-icon>
                    <span>Send Message</span>
                </button>
            </form>

        </section>

    </article>
</template>
<script>
export default {
    name: 'ContactView',
};
</script>