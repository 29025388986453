<template>
    <li class="timeline-item">
        <h4 class="h4 timeline-item-title">{{ title }}</h4>
        <span>{{ date }}</span>
        <p class="timeline-text">{{ description }}</p>
    </li>
</template>

<script>
export default {
    name: 'EducationItem',
    props: {
        title: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
};
</script>